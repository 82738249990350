import { ActionTypes } from "../constants/action_types";

const initialMode = 0;

export const apiModeReducer = (state = initialMode, { type, apiMode }) => {
    switch (type) {
        case ActionTypes.RUN_API_MODE:
            return apiMode
        default:
            return state
    }
}