import React from "react";
import Auth from "./Auth";
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  const tokeninfo1 = localStorage.getItem("tokeninfo");
  const tokeninfo = JSON.parse(tokeninfo1);
  return (
    Auth.getAuth() ? (
      <Outlet />
    ) : (
      <Navigate to={{
        pathname: "/",
      }} />
    )
  );
};

export default ProtectedRoutes;
