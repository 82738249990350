import { ActionTypes } from '../constants/action_types';

const initialTabMod = { indexVal: 0, status: 'all', jobId: '' };

export const candidateTabVal = (state = initialTabMod, { type, tabMode }) => {
  switch (type) {
    case ActionTypes.CANDIDATE_TAB_NUM:
      return tabMode;
    default:
      return state;
  }
};
