import React, { Component, Suspense } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import "./scss/style.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import ProtectedRoutes from "./auth/ProtectedRoutes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotpassword/ForgotPasswordForm")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/passwordforgot/PasswordForgate")
);

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const EmailJobDetailsPage = React.lazy(() =>
  import("./views/pages/login/EmailJobDetailsPage")
);

const CompanyRegistration = React.lazy(() => import("./views/pages/register/CompanyRegister"));
const CandidateRegistration = React.lazy(() => import("./views/pages/register/CandidateRegister"));

const JobShare = React.lazy(() => import("./views/pages/login/jobShare"));
const Website = React.lazy(() => import("./views/pages/website/home/Home"));

const NewJobs = React.lazy(() => import("./views/pages/website/website_jobs/ListJobs"));

const Aboutus = React.lazy(() => import("./views/pages/website/about/About"));

const Contactus = React.lazy(() => import("./views/pages/website/contact/Contact"));

const Login = React.lazy(() => import("./views/pages/login/LoginPage"));

// const Blogs = React.lazy(() => import("./views/pages/website/blog/Blog"));

const Prices = React.lazy(() => import("./views/pages/website/pricing/Pricing"));

const TermsConditions = React.lazy(() => import("./views/pages/website/terms/Terms"));

const PrivacyPolicy = React.lazy(() => import("./views/pages/website/privacy_policy/Privacy"));

const ClientPage = React.lazy(() => import("./views/pages/website/client/Client"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>

            <Route path="/" name="Website" element={<Website />} />

            <Route path="/about" name="About" element={<Aboutus />} />

            <Route path="/job-list" name="Jobs" element={<NewJobs />} />

            {/* <Route path="/blog" name="Blog" element={<Blogs />} />

              <Route
                path="/blog-details"
                name="Blog"
                element={<BlogDetail />}
              /> */}

            <Route path="/contact" name="Contact Us" element={<Contactus />} />
            <Route path="/login" name="Login" element={<Login />} />
            <Route path="/terms-conditions" name="Terms & Condtions" element={<TermsConditions />} />
            <Route path="/pricing" name="Pricing" element={<Prices />} />
            <Route path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicy />} />
            <Route path="/client-registration" name="Client Registration" element={<ClientPage />} />
            <Route path="/jobs-detail/:job" name="Jobs Details" element={<JobShare />} />
            <Route exact path="/company-registration" name="Company Registration Page" element={<CompanyRegistration />} />
            <Route exact path="/applicant-registration" name="Candidate Registration Page" element={<CandidateRegistration />} />

            <Route
              exact
              path="/referaljob"
              name="Referal Job"
              element={<EmailJobDetailsPage />}
            />
            {/* sent mail for forgate password*/}
            <Route
              exact
              path="/forgotpassword"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            {/* password forgate create new password */}
            <Route
              exact
              path="/passwordforgot"
              name=" Password Forgot"
              element={<ResetPassword />}
            />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/" element={<ProtectedRoutes />}>
              <Route
                exact
                path="*"
                name="dashboard"
                element={<DefaultLayout />}
              />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
