import { ActionTypes } from "../constants/action_types";
const initialvalue = null;

export const changeTenantReducer = (
  state = initialvalue,
  { type, tenantIdValue, ...rest }
) => {
  switch (type) {
    case ActionTypes.CHANGE_TENANT:
      return { ...state, tenantIdValue };
    default:
      return state;
  }
};
