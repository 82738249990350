import { ActionTypes } from '../constants/action_types'
const initialValue = {};

export const apiErrMsg = (state = initialValue, { type, errMsg }) => {
    switch (type) {
        case ActionTypes.API_ERR_MSG:
            return errMsg
        default:
            return state
    }
}