const { ActionTypes } = require("../constants/action_types");

const initialState = true;

export const sidebarReducer = (state = initialState, { type, sidebarShow }) => {
  switch (type) {
    case ActionTypes.SET_SIDEBAR:
      return sidebarShow; //sidebarShow 
    default:
      return state;
  }
};
