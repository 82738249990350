export const ActionTypes = {
  SET_SIDEBAR: "SET_SIDEBAR",
  SET_LOGIN_USER: "SET_LOGIN_USER",
  REMOVE_LOGIN_USER: "REMOVE_LOGIN_USER",
  ADD_AUTHTOKEN: "ADD_AUTHTOKEN",
  REMOVE_AUTHTOKEN: "REMOVE_AUTHTOKEN",
  CHANGE_TENANT: "CHANGE_TENANT",
  RUN_API_MODE: "RUN_API_MODE",
  CANDIDATE_TAB_NUM: "CANDIDATE_TAB_NUM",
  API_ERR_MSG: "API_ERR_MSG",
  USER_ADDRESS:"USER_ADDRESS" 
};
