import { ActionTypes } from "../constants/action_types";
const initialUser = null;

export const userReduer = (state = initialUser, { type, user }) => {
  switch (type) {
    case ActionTypes.SET_LOGIN_USER:
      return { ...state, user };
    case ActionTypes.REMOVE_LOGIN_USER:
      return { ...state, user };
    default:
      return state;
  }
};
