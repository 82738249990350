import { combineReducers } from "redux";
import { sidebarReducer } from "./sidebarReducer";
import { userReduer } from "./userReducer";
import { AuthReduer } from "./authTokenReducer";
import { changeTenantReducer } from "./changeTenantReducer";
import { apiModeReducer } from "./runAPIReducer"
import { candidateTabVal } from "./candidateTabNumReducer"
import { apiErrMsg } from './apiErrMsgReducer';
import { userAddressReducer } from "./userAddressReducer";

const reducers = combineReducers({
  sidebarStatus: sidebarReducer,
  logedUser: userReduer,
  authToken: AuthReduer,
  tenantIdValue: changeTenantReducer,
  apiModeReducer: apiModeReducer,
  candidateTabVal: candidateTabVal,
  apiErrMsg: apiErrMsg,
  userAddress: userAddressReducer
});

export default reducers;
